<template>
  <div v-bind="$attrs">
    <el-upload
      name="file"
      class="avatar-uploader"
      :headers="{ Authorization: 'Bearer ' + $store.getters.token || '' }"
      :action="domainName + UPLOAD_IMG_URL"
      accept=".png,.jpg,.gif"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="imageUrl || imgUrl" :src="imageUrl ? imageUrl : `${STATIC_IMG_URL}${imgUrl}`" class="avatar" alt="" />
      <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
    </el-upload>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, defineExpose, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { beforeAvatarUpload } from "@/utils/images";
import { domainName, STATIC_IMG_URL } from "@/config";
import { UPLOAD_IMG_URL } from "@/api/public";
import { useStore } from "vuex";
import { SUCCESS_CODE, TOKEN_OVERDUE_CODE } from "@/constant";

const store = useStore();
const props = defineProps({
  imgUrl: {
    type: String,
    default: ""
  }
});
const { imgUrl } = toRefs(props);
const imageUrl = ref("");
const emits = defineEmits(["onScs"]);

const handleAvatarSuccess = (res, file) => {
  let { code, data, message } = res;
  if (code === SUCCESS_CODE) {
    imageUrl.value = URL.createObjectURL(file.raw);
    ElMessage.success("上传成功");
    emits("onScs", data);
  } else {
    if (code === TOKEN_OVERDUE_CODE) {
      ElMessage.error(message);
      store.dispatch("user/logout");
    } else {
      ElMessage.error(message);
    }
  }
};

const clearImgUrl = () => {
  imageUrl.value = "";
};
defineExpose({ clearImgUrl });
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
