<template>
  <el-dialog
    v-bind="$attrs"
    @open="initEditor"
    :close-on-click-modal="false"
    :show-close="false"
    :title="formData.id ? '编辑' : '添加'"
    lock-scroll
  >
    <el-form
      ref="ruleFormRef"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="turntable-form"
      :size="formSize"
    >
      <el-form-item label="活动名称" prop="title">
        <el-input v-model="formData.title" maxlength="15" placeholder="请输入活动名称" class="input-w" />
      </el-form-item>
      <el-form-item label="活动状态" prop="status">
        <el-select v-model="formData.status" placeholder="请选择活动状态">
          <el-option label="关闭" value="0" />
          <el-option label="开启" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="活动类型" prop="type">
        <el-select v-model="formData.type" placeholder="请选择活动类型">
          <el-option label="sdk支付" value="0" />
          <el-option label="vip" value="1" />
          <el-option label="创角" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="产品" prop="productId" v-if="Number(formData.type) === 0">
        <GameList v-model="formData.productId" />
      </el-form-item>
      <el-form-item label="转盘图" prop="itemsImg">
        <one-upload @onScs="onScs" ref="itemsImgRef" :imgUrl="formData.itemsImg" />
      </el-form-item>
      <el-form-item label="按钮图" prop="buttonImg">
        <one-upload @onScs="onScs2" ref="buttonImgRef" :imgUrl="formData.buttonImg" />
      </el-form-item>
      <el-form-item label="活动规则" prop="tips">
        <div ref="editorBox" id="editorBox2"></div>
      </el-form-item>
      <div v-if="Number(formData.type) === 1">
        <el-form-item label="vip等级" prop="mainKey">
          <el-input v-model="formData.mainKey" placeholder="请输入vip等级" class="input-w" />
        </el-form-item>
        <el-form-item label="积分消费" prop="cost">
          <el-input v-model="formData.cost" placeholder="请输入积分消费" class="input-w" />
        </el-form-item>
        <el-form-item label="打折后价格" prop="realityCost">
          <el-input v-model="formData.realityCost" placeholder="请输入打折后价格" class="input-w" />
        </el-form-item>
      </div>
      <div v-if="Number(formData.type) === 0">
        <div class="d-fx" v-for="(domain, index) in formData.showWhere" :key="index">
          <el-form-item
            label="充值订单笔数"
            :prop="'showWhere.' + index + '.num'"
            :rules="{
              required: true,
              message: '请输入充值订单笔数',
              trigger: 'blur'
            }"
          >
            <el-input v-model="domain.num" placeholder="请输入充值订单笔数" />
          </el-form-item>
          <el-form-item
            label="充值金额"
            :prop="'showWhere.' + index + '.money'"
            :rules="{
              required: true,
              message: '请输入充值金额',
              trigger: 'blur'
            }"
          >
            <el-input v-model="domain.money" placeholder="请输入充值金额" />
          </el-form-item>
          <el-button class="delete-btn" @click.prevent="removeCondition(domain)">删除</el-button>
        </div>
        <el-form-item label="">
          <el-button @click="addCondition">
            <el-icon><plus /></el-icon>添加弹出配置
          </el-button>
        </el-form-item>
      </div>
      <h2 class="rule-title">
        <span v-if="Number(formData.type) === 0">第一次抽奖</span>
        <span v-else>免费抽奖</span>
      </h2>
      <div class="award-config">
        <dl v-for="(domain, index) in formData.awardList" :key="index">
          <dt class="d-fx">
            <el-form-item
              :label="`奖励${index + 1}`"
              :prop="'awardList.' + index + '.name'"
              :rules="{
                required: true,
                message: '请输入奖励内容',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.name" class="input-w2" placeholder="请输入奖励内容" />
            </el-form-item>
            <el-form-item
              label="奖品类型"
              :prop="'awardList.' + index + '.type'"
              :rules="{
                required: true,
                message: '请输入充值金额',
                trigger: 'blur'
              }"
            >
              <el-select v-model="domain.type" placeholder="请选择奖品类型">
                <el-option label="代金劵" value="1" />
                <el-option label="实物" value="2" />
                <el-option label="积分" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="概率"
              :prop="'awardList.' + index + '.rate'"
              :rules="{
                required: true,
                message: '请输入概率',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.rate" placeholder="请输入概率" />
            </el-form-item>
          </dt>
          <dd class="award-output">
            <div class="d-fx" v-for="(item, idx) in domain.reward" :key="idx">
              <el-form-item
                label="奖励内容"
                :prop="'awardList.' + index + '.reward.' + idx + '.name'"
                :rules="{
                  required: true,
                  message: '请输入奖励内容',
                  trigger: 'blur'
                }"
              >
                <el-input placeholder="请输入奖励内容" v-model="item.name" />
              </el-form-item>
              <el-form-item
                label="每日产出"
                :prop="'awardList.' + index + '.reward.' + idx + '.limItnum'"
                :rules="{
                  required: true,
                  message: '请输入每日产出',
                  trigger: 'blur'
                }"
              >
                <el-input v-model="item.limItnum" placeholder="请输入每日产出" />
              </el-form-item>
              <el-button class="delete-btn" @click.prevent="removeAwardOutput(0, index, item)">删除</el-button>
            </div>
            <el-form-item label="">
              <el-button @click="addAwardOutput(0, index)">
                <el-icon><plus /></el-icon>增加产出和奖励内容
              </el-button>
            </el-form-item>
          </dd>
        </dl>
      </div>
      <h2 class="rule-title">第二次抽奖</h2>
      <div class="award-config">
        <dl v-for="(domain, index) in formData.awardList1" :key="index">
          <dt class="d-fx">
            <el-form-item
              :label="`奖励${index + 1}`"
              :prop="'awardList.' + index + '.name'"
              :rules="{
                required: true,
                message: '请输入奖励内容',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.name" class="input-w2" placeholder="请输入奖励内容" />
            </el-form-item>
            <el-form-item
              label="奖品类型"
              :prop="'awardList1.' + index + '.type'"
              :rules="{
                required: true,
                message: '请输入充值金额',
                trigger: 'blur'
              }"
            >
              <el-select v-model="domain.type" placeholder="请选择奖品类型">
                <el-option label="代金劵" value="1" />
                <el-option label="实物" value="2" />
                <el-option label="积分" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="概率"
              :prop="'awardList1.' + index + '.rate'"
              :rules="{
                required: true,
                message: '请输入概率',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.rate" placeholder="请输入概率" />
            </el-form-item>
          </dt>
          <dd class="award-output">
            <div class="d-fx" v-for="(item, idx) in domain.reward" :key="idx">
              <el-form-item
                label="奖励内容"
                :prop="'awardList1.' + index + '.reward.' + idx + '.name'"
                :rules="{
                  required: true,
                  message: '请输入奖励内容',
                  trigger: 'blur'
                }"
              >
                <el-input placeholder="请输入奖励内容" v-model="item.name" />
              </el-form-item>
              <el-form-item
                label="每日产出"
                :prop="'awardList1.' + index + '.reward.' + idx + '.limItnum'"
                :rules="{
                  required: true,
                  message: '请输入每日产出',
                  trigger: 'blur'
                }"
              >
                <el-input v-model="item.limItnum" placeholder="请输入每日产出" />
              </el-form-item>
              <el-button class="delete-btn" @click.prevent="removeAwardOutput(1, index, item)">删除</el-button>
            </div>
            <el-form-item label="">
              <el-button @click="addAwardOutput(1, index)">
                <el-icon><plus /></el-icon>增加产出和奖励内容
              </el-button>
            </el-form-item>
          </dd>
        </dl>
      </div>
      <h2 class="rule-title" v-if="Number(formData.type) === 0">第三次抽奖</h2>
      <div class="award-config" v-if="Number(formData.type) === 0">
        <dl v-for="(domain, index) in formData.awardList2" :key="index">
          <dt class="d-fx">
            <el-form-item
              :label="`奖励${index + 1}`"
              :prop="'awardList.' + index + '.name'"
              :rules="{
                required: true,
                message: '请输入奖励内容',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.name" class="input-w2" placeholder="请输入奖励内容" />
            </el-form-item>
            <el-form-item
              label="奖品类型"
              :prop="'awardList2.' + index + '.type'"
              :rules="{
                required: true,
                message: '请输入充值金额',
                trigger: 'blur'
              }"
            >
              <el-select v-model="domain.type" placeholder="请选择奖品类型">
                <el-option label="代金劵" value="1" />
                <el-option label="实物" value="2" />
                <el-option label="积分" value="3" />
              </el-select>
            </el-form-item>
            <el-form-item
              label="概率"
              :prop="'awardList2.' + index + '.rate'"
              :rules="{
                required: true,
                message: '请输入概率',
                trigger: 'blur'
              }"
            >
              <el-input v-model="domain.rate" placeholder="请输入概率" />
            </el-form-item>
          </dt>
          <dd class="award-output">
            <div class="d-fx" v-for="(item, idx) in domain.reward" :key="idx">
              <el-form-item
                label="奖励内容"
                :prop="'awardList2.' + index + '.reward.' + idx + '.name'"
                :rules="{
                  required: true,
                  message: '请输入奖励内容',
                  trigger: 'blur'
                }"
              >
                <el-input placeholder="请输入奖励内容" v-model="item.name" />
              </el-form-item>
              <el-form-item
                label="每日产出"
                :prop="'awardList2.' + index + '.reward.' + idx + '.limItnum'"
                :rules="{
                  required: true,
                  message: '请输入每日产出',
                  trigger: 'blur'
                }"
              >
                <el-input v-model="item.limItnum" placeholder="请输入每日产出" />
              </el-form-item>
              <el-button class="delete-btn" @click.prevent="removeAwardOutput(2, index, item)">删除</el-button>
            </div>
            <el-form-item label="">
              <el-button @click="addAwardOutput(2, index)">
                <el-icon><plus /></el-icon>增加产出和奖励内容
              </el-button>
            </el-form-item>
          </dd>
        </dl>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(ruleFormRef)">取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import WangEditor from "wangeditor";
import { nextTick, onBeforeUnmount, reactive, ref, defineEmits } from "vue";
import GameList from "@/components/GameList";
import OneUpload from "@/components/OneUpload";
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { lotteryForm } from "@/api/activity";
import { useStore } from "vuex";
import { initData } from "@/store/modules/turntable";
const store = useStore();
const formSize = ref("default");
const ruleFormRef = ref();
const buttonImgRef = ref(null);
const itemsImgRef = ref(null);
const formData = ref({ ...initData });

// Editor实例
const editorBox = ref();
let instance;

const onScs = (data) => {
  formData.value.itemsImg = data.filePath;
};

const onScs2 = (data) => {
  formData.value.buttonImg = data.filePath;
};

const emits = defineEmits(["update:modelValue", "saveSuc"]);

const rules = reactive({
  title: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
  productId: [{ required: true, message: "请选择产品", trigger: "blur" }],
  itemsImg: [{ required: true, message: "请上传转盘图", trigger: "blur" }],
  buttonImg: [{ required: true, message: "请上传按钮图", trigger: "blur" }],
  tips: [{ required: true, message: "请输入活动规则", trigger: "blur" }],
  mainKey: [{ required: true, message: "请输入vip等级0-8", trigger: "blur" }],
  cost: [{ required: true, message: "请输入积分消费", trigger: "blur" }],
  realityCost: [{ required: true, message: "请输入积分消费打折后价格", trigger: "blur" }]
});

const removeCondition = (item) => {
  const index = formData.value.showWhere.indexOf(item);
  if (index !== -1) {
    formData.value.showWhere.splice(index, 1);
  }
};

const addCondition = () => {
  formData.value.showWhere.push({ limItnum: "", name: "" });
};

const addAwardOutput = (type, index) => {
  if (type === 0) {
    formData.value.awardList[index].reward.push({
      key: Date.now(),
      value: ""
    });
  } else if (type === 1) {
    formData.value.awardList1[index].reward.push({
      key: Date.now(),
      value: ""
    });
  } else {
    formData.value.awardList2[index].reward.push({
      key: Date.now(),
      value: ""
    });
  }
};

const removeAwardOutput = (type, index, item) => {
  if (type === 0) {
    const idx = formData.value.awardList[index].reward.indexOf(item);
    if (idx !== -1) {
      formData.value.awardList[index].reward.splice(idx, 1);
    }
  } else if (type === 1) {
    const idx = formData.value.awardList1[index].reward.indexOf(item);
    if (idx !== -1) {
      formData.value.awardList1[index].reward.splice(idx, 1);
    }
  } else {
    const idx = formData.value.awardList2[index].reward.indexOf(item);
    if (idx !== -1) {
      formData.value.awardList2[index].reward.splice(idx, 1);
    }
  }
};

const resetForm = (formEl) => {
  store.commit("turntable/restoreTurntableData");
  buttonImgRef.value.clearImgUrl();
  itemsImgRef.value.clearImgUrl();
  emits("update:modelValue", false);
  instance.txt.clear();
  if (!formEl) return;
  formEl.resetFields();
};

const submitForm = async (formEl) => {
  formData.value.tips = instance.txt.html();
  if (!formEl) return;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await lotteryForm({
        ...formData.value,
        config: {
          showWhere: formData.value.showWhere,
          items: [formData.value.awardList, formData.value.awardList1, formData.value.awardList2]
        }
      });
      ElMessage.success(formData.value.id ? "编辑成功" : "添加成功");
      resetForm(formEl);
      emits("saveSuc");
    } else {
      console.log("error submit!", fields);
      ElMessage.error("请完善表单");
    }
  });
};

const initEditor = () => {
  nextTick(() => {
    formData.value = store.getters.turntableData;
    if (instance === undefined) {
      instance = new WangEditor(editorBox.value);
      instance.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        "table",
        "splitLine",
        "undo",
        "redo"
      ];
      instance.create();
      instance.txt.html(formData.value.tips);
    } else {
      instance.txt.html(formData.value.tips);
    }
  });
};

onBeforeUnmount(() => {
  if (instance !== undefined) {
    instance.destroy();
    instance = null;
  }
});
</script>

<style lang="scss" scoped>
.turntable-form {
  height: 60vh;
  overflow-y: auto;
  padding-right: 15px;
}
.delete-btn {
  margin-left: 15px;
}
.award-config {
  padding: 20px 20px 10px 0;
  margin-bottom: 25px;
  border: 1px solid #f0f0f0;
}
.award-output {
  padding-left: 75px;
}
.rule-title {
  font-size: 18px;
  padding-bottom: 10px;
}
</style>
