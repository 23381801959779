<template>
  <el-select-v2
    class="game-list"
    v-bind="$attrs"
    :options="dataList"
    placeholder="请选择产品"
    multiple
    collapse-tags
    filterable
  />
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const dataList = ref([]);
const store = useStore();

const getListData = async () => {
  let data = store.getters.gameList;
  if (data.length) {
    dataList.value = data;
  } else {
    dataList.value = await store.dispatch("public/getGameList");
  }
};
getListData();
</script>

<style lang="scss" scoped>
.game-list {
  width: 300px;
}
</style>
