import { ElMessage } from "element-plus";

// 图片大小
export const beforeAvatarUpload = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    ElMessage.error("大小不能超过2MB!");
  }
  return isLt2M;
};
