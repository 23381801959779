import request from "@/utils/request";

/* 抽奖列表 */
export const getLotteryList = (params) => {
  return request({
    url: "/act/lottery/list",
    params
  });
};

/* 抽奖表单提交 */
export const lotteryForm = (data) => {
  return request({
    url: "/act/lottery/save",
    method: "POST",
    data
  });
};

/* 抽奖编辑 */
export const editLottery = (params) => {
  return request({
    url: "/act/lottery/find",
    params
  });
};

/* 抽奖记录 */
export const lotteryLog = (params) => {
  return request({
    url: "/act/lottery/logList",
    params
  });
};

/* 删除一条记录(抽奖) */
export const removeLottery = (data) => {
  return request({
    url: "/act/lottery/del",
    method: "POST",
    data
  });
};

/* 代金劵发放列表 */
export const voucherGrantList = (params) => {
  return request({
    url: "/act/voucher/auto/list",
    params
  });
};

/* 代金劵配置列表 */
export const voucherConfigList = (params) => {
  return request({
    url: "act/voucher/autoConfig/list",
    params
  });
};

/* 代金劵配置增加，删除 */
export const vcrCgAddRe = (data) => {
  return request({
    url: "act/voucher/autoConfig/save",
    method: "POST",
    data
  });
};

/* 代金劵撤回 */
export const vcrWithdraw = (data) => {
  return request({
    url: "act/voucher/auto/del",
    method: "POST",
    data
  });
};

/* 代金劵发放 */
export const vcrSend = (data) => {
  return request({
    url: "act/voucher/auto/send",
    method: "POST",
    data
  });
};

/* 代金劵发放表单保存 */
export const vcrFmSave = (data) => {
  return request({
    url: "act/voucher/auto/save",
    method: "POST",
    data
  });
};
