<template>
  <el-dialog v-bind="$attrs" title="提示" width="26%" :show-close="false">
    <span>{{ dialogCon }}</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="yesRemove">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
const props = defineProps({
  remove: {
    type: Function,
    required: true
  },
  dialogCon: {
    type: String,
    default: "你确定要删除吗？"
  }
});
const emits = defineEmits(["update:modelValue"]);
const cancel = () => {
  emits("update:modelValue", false);
};
const yesRemove = () => {
  props.remove && props.remove();
  cancel();
};
</script>

<style scoped></style>
