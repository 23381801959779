<template>
  <div class="main-view-con">
    <div class="d-fx search-bk">
      <el-button type="primary" @click="toTurntableForm">添加活动</el-button>
      <el-input class="search-input" v-model="searchParam.name" placeholder="请输入活动名称" />
    </div>
    <el-table :data="data.data || []" border v-loading="isLoading">
      <el-table-column prop="id" label="活动ID" width="100" />
      <el-table-column prop="name" label="活动名称" />
      <el-table-column prop="createTime" label="创建时间" #default="{ row }">
        <span>{{ $filters.dateFilter(row.createTime * 1000) }}</span>
      </el-table-column>
      <el-table-column prop="status" label="活动状态" #default="{ row }">
        <span>{{ row.status ? "开启" : "关闭" }}</span>
      </el-table-column>
      <el-table-column prop="type" label="活动类型" #default="{ row }">
        <span>{{ Number(row.type) === 0 ? "sdk支付" : Number(row.type) === 1 ? "vip" : "其它" }}</span>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="editData(row)">编辑</el-button>
          <el-button @click="seaRecord(row)">抽奖记录</el-button>
          <el-button type="danger" @click="showRemoveDialog(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-page">
      <el-pagination
        background
        layout="prev, pager, next, total"
        :total="data.total || 0"
        @current-change="handleCurrentChange"
      />
    </div>
    <turntable-form v-model="dialogFormVisible" @saveSuc="saveSuc" />
    <remove-dialog v-model="dialogVisible" :remove="yesRemove" />
  </div>
</template>

<script>
export default {
  name: "articleTurntable"
};
</script>
<script name="ArticleTurntable" setup>
import { reactive, ref, watch } from "vue";
import { editLottery, getLotteryList, removeLottery } from "@/api/activity";
import { debounce } from "@/utils";
import { useAsync } from "@/utils/useAsync";
import TurntableForm from "./Form";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import RemoveDialog from "@/components/RemoveDialog";

const dialogVisible = ref(false);
const removeId = ref("");

const router = useRouter();
const store = useStore();
const dialogFormVisible = ref(false);

const searchParam = reactive({
  page: 1,
  paginate: 10,
  isUpdate: false,
  name: ""
});

const handleCurrentChange = (val) => {
  searchParam.page = val;
};

const saveSuc = () => {
  searchParam.isUpdate = !searchParam.isUpdate;
};

const { data, run, isLoading } = useAsync();

watch(
  [searchParam],
  debounce(() => {
    run(getLotteryList({ ...searchParam }));
  }, 500),
  {
    immediate: true
  }
);

const toTurntableForm = () => {
  dialogFormVisible.value = true;
};

const seaRecord = (row) => {
  router.replace(`/activity/lotteryRecord/${row.id}`);
};

const editData = async (row) => {
  const res = await editLottery({ id: row.id });
  let { id, title, status, type, productId, tips, itemsImg, buttonImg, config, mainKey, cost, realityCost } = res;
  store.commit("turntable/setTurntableData", {
    id,
    title,
    mainKey,
    cost,
    realityCost,
    status: String(status),
    type: String(type),
    productId: (productId && productId.map(Number)) || [],
    tips,
    itemsImg,
    buttonImg,
    showWhere: config.showWhere || [],
    awardList: config.items[0] || [],
    awardList1: config.items[1] || [],
    awardList2: config.items[2] || []
  });
  dialogFormVisible.value = true;
};

const showRemoveDialog = (row) => {
  dialogVisible.value = true;
  removeId.value = row.id;
};

const yesRemove = async () => {
  await removeLottery({ id: removeId.value });
  ElMessage.error("删除成功");
  saveSuc();
};
</script>

<style lang="scss" scoped></style>
